export const environment = {
  id: "roamstay-dev",
  production: false,
  showAllMenus: true,
  dashboardUrl: 'https://dev.app.roamstay.com',
  socketUrl: 'https://devapi.roamstay.com',
  apiUrl: 'https://devapi.roamstay.com/pms',
  mixpanelToken: 'e404dd83dc1f4dd39087a5c08ec81565',
  googleClientId: '879107131855-ajs4fsecjaoekdn2jpnbjqqbhqt2lro2.apps.googleusercontent.com',
  googleMapApiKey: "AIzaSyBvmLRshpYwiWI0j1czi7W6ZohQawUbmds",

  // firebase
  firebase: {
    projectId: "roamstay-nodejs-backend",
    appId: "1:31122398450:web:ac889c98056506438e446b",
    apiKey: "AIzaSyCau9jfAs3fBWtqOaOes2OPHDsvJ8Cmo0s",
    authDomain: "roamstay-nodejs-backend.firebaseapp.com",
    messaging: {
      messagingSenderId: "31122398450",
      vapidKey: "BFR0KaaBt6S2qBYPr0LFp-psFuMoiTOopGMFWUUL27Wbun4aS_bvz1_NR0mWPK-FTc8sQRAb3XsekxrOfBMRNHo",
    }
  }
};
